import React, { lazy, Suspense } from 'react';
import { Box, CircularProgress, styled } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ProvideAuth } from '../hooks';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import { FullSizeCenteredBox } from './Common';
import { ProvideSettings } from '../hooks/useSettings';
import { ProvideScheduleSearch } from '../hooks/useScheduleSearch';

const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage/NotFoundPage'));
const Dashboard = lazy(() => import('./DashboardPage/DashboardPage'));
const Settings = lazy(() => import('./SettingsPage/SettingsPage'));
const Messages = lazy(() => import('./MessagesPage/MessagesPage'));
const LicenseNumber = lazy(() => import('./LicenseNumberPage/LicenseNumberPage'));

const StyledApp = styled(Box)`
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

function App() {
    return (
        <StyledApp>
            <BrowserRouter>
                <Suspense
                    fallback={
                        <FullSizeCenteredBox>
                            <CircularProgress />
                            Loading...
                        </FullSizeCenteredBox>
                    }>
                    <ProvideAuth>
                        <ProvideSettings>
                            <ProvideSnackBar>
                                <ProvideScheduleSearch>
                                    <Routes>
                                        <Route path='/dashboard' element={<Dashboard />} />
                                        <Route path='/settings' element={<Settings />} />
                                        <Route path='/messages' element={<Messages />} />
                                        <Route path='/' element={<Navigate to={'/login'} />} />
                                        <Route path='/login' element={<LoginPage />} />
                                        <Route path='/license-number' element={<LicenseNumber />} />
                                        <Route path='*' element={<NotFoundPage />} />
                                    </Routes>
                                </ProvideScheduleSearch>
                            </ProvideSnackBar>
                        </ProvideSettings>
                    </ProvideAuth>
                </Suspense>
            </BrowserRouter>
        </StyledApp>
    );
}

export default App;
