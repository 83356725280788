import { createContext, useContext, useEffect, useState } from 'react';
import { DefaultMenuItem, MenuItemType, Props } from '../@types';
import { LinesResponse, StopDetails, StopResponse, WorkplaceListResponse } from '../@types/scheduler';
import { useAuth } from './useAuth';
import { useProvideSnackBar } from './useSnackBar';

const SCHEDULER_API_ENDPOINT = process.env.REACT_APP_SCHEDULER_API_ENDPOINT || '';

type ScheduleSearchContextType = {
    workplaces: MenuItemType[];
    workplace: MenuItemType;
    setWorkplace: (value: MenuItemType) => void;
    lines: MenuItemType[];
    line: MenuItemType;
    setLine: (value: MenuItemType) => void;
    stops: StopDetails[][];
    lineStops: StopDetails[];
    setLineStops: (stops: StopDetails[]) => void;
    loadingWorkplaces: boolean;
    loadingLines: boolean;
    loadingStops: boolean;
};

export const ScheduleSearchContext = createContext<ScheduleSearchContextType>({
    workplaces: [],
    workplace: DefaultMenuItem,
    setWorkplace(value: MenuItemType): void {},
    lines: [],
    line: DefaultMenuItem,
    setLine(value: MenuItemType): void {},
    stops: [],
    lineStops: [],
    setLineStops(stops: StopDetails[]): void {},
    loadingWorkplaces: false,
    loadingLines: false,
    loadingStops: false,
});

export const useScheduleSearch = () => useContext(ScheduleSearchContext);

export const ProvideScheduleSearch = ({ children }: Props) => {
    const { user } = useAuth();
    const { showResponseError, showError } = useProvideSnackBar();

    const [workplace, setWorkplace] = useState<MenuItemType>(DefaultMenuItem);
    const [line, setLine] = useState<MenuItemType>(DefaultMenuItem);
    const [lineStops, setLineStops] = useState<StopDetails[]>([]);

    const [workplaces, setWorkplaces] = useState<MenuItemType[]>([DefaultMenuItem]);
    const [loadingWorkplaces, setLoadingWorkplaces] = useState<boolean>(false);

    const [lines, setLines] = useState<MenuItemType[]>([DefaultMenuItem]);
    const [loadingLines, setLoadingLines] = useState<boolean>(false);

    const [stops, setStops] = useState<StopDetails[][]>([]);
    const [loadingStops, setLoadingStops] = useState<boolean>(false);

    const getWorkplaces = async () => {
        if (!user?.accessToken) {
            return;
        }

        setLoadingWorkplaces(true);

        try {
            const response = await fetch(SCHEDULER_API_ENDPOINT + '/api/busman/workplaces', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + user.accessToken,
                },
                mode: 'cors',
            });

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: WorkplaceListResponse = await response.json();
            const newWorkplaceList = [
                DefaultMenuItem,
                ...body.Workplaces.map(w => {
                    return {
                        value: w,
                        label: w,
                    };
                }),
            ];
            setWorkplaces(newWorkplaceList);
        } catch (error: any) {
            showError('Hiba történt a partnerek lekérdezése során');
        } finally {
            setLoadingWorkplaces(false);
        }
    };

    const getLines = async () => {
        if (!user?.accessToken || !workplace.value) {
            return;
        }

        setLoadingLines(true);

        try {
            const response = await fetch(SCHEDULER_API_ENDPOINT + '/api/busman/lines?' + new URLSearchParams({ Workplace: workplace.value }), {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + user.accessToken,
                },
                mode: 'cors',
            });

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: LinesResponse = await response.json();
            const newLineList = [
                DefaultMenuItem,
                ...body.LineNames.map(w => {
                    return {
                        value: w,
                        label: w,
                    };
                }),
            ];
            setLines(newLineList);
        } catch (error: any) {
            console.log(error);
            showError('Hiba történt a partnerek lekérdezése során');
        } finally {
            setLoadingLines(false);
        }
    };

    const getStops = async () => {
        if (!user?.accessToken || workplace.value === ' ' || line.value === ' ') {
            return;
        }

        setLoadingStops(true);
        try {
            const response = await fetch(
                SCHEDULER_API_ENDPOINT +
                    '/api/busman/stops?' +
                    new URLSearchParams({
                        Workplace: workplace.value,
                        Line: line.value,
                    }).toString(),
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + user.accessToken,
                    },
                    mode: 'cors',
                }
            );

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: StopResponse = await response.json();
            setStops(body.Lines);
        } catch (error: any) {
            console.log(error);
            showError('Hiba történt a partnerek lekérdezése során');
        } finally {
            setLoadingStops(false);
        }
    };

    useEffect(() => {
        getWorkplaces();
    }, [user?.accessToken]);

    useEffect(() => {
        getLines();
        setLine(DefaultMenuItem);
    }, [workplace]);

    useEffect(() => {
        getStops();
    }, [line]);

    return (
        <ScheduleSearchContext.Provider
            value={{
                workplaces,
                workplace,
                setWorkplace,
                lines,
                line,
                setLine,
                stops,
                lineStops,
                setLineStops,
                loadingWorkplaces,
                loadingLines,
                loadingStops,
            }}>
            {children}
        </ScheduleSearchContext.Provider>
    );
};
