export const getErrorMessage = (response: Response) => {
    if (response.status === 400) {
        return 'Hibás kérés';
    }
    if (response.status === 401) {
        return 'Sikertelen azonosítás, lépjen be újra!';
    }
    if (response.status === 403) {
        return 'Hozzáférés megtagadva!';
    }
    if (response.status === 409) {
        return 'A mentés nem sikerült, frissítse az oldalt!';
    }
    return response.statusText || `${response.status} | Kommunikációs hiba`;
};
