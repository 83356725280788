import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Props } from '../@types';
import { createTheme, GlobalStyles, ThemeProvider } from '@mui/material';
import { get, set } from 'idb-keyval';
import { useAuth } from './useAuth';
import { Role } from '../@types/auth';

type SettingsContextType = {
    fontSize: number;
    setFontSize: (v: number) => void;
    isDarkMode: boolean;
    setIsDarkMode: (v: boolean) => void;
    isDebug: boolean;
    setIsDebug: (v: boolean) => void;
    licenseNumber: string;
    setLicenseNumber: (value: string) => void;
    countOfUnreadMessages: number;
    setCountOfUnreadMessages: (value: number) => void;
};
export const SettingsContext = createContext<SettingsContextType>({
    fontSize: 100,
    setFontSize: (value: number) => {},
    isDarkMode: true,
    setIsDarkMode: (value: boolean) => {},
    isDebug: true,
    setIsDebug: (value: boolean) => {},
    licenseNumber: '',
    setLicenseNumber: (value: string) => {},
    countOfUnreadMessages: 0,
    setCountOfUnreadMessages: (value: number) => {},
});

export const useSettings = () => useContext(SettingsContext);

export const ProvideSettings = ({ children }: Props) => {
    const { user } = useAuth();
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
    const [fontSize, setFontSize] = useState(14);
    const [licenseNumber, setLicenseNumber] = useState<string>('');
    const [isDebug, setIsDebug] = useState<boolean>(false);
    const [countOfUnreadMessages, setCountOfUnreadMessages] = useState<number>(0);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: isDarkMode ? 'dark' : 'light',
                },
                typography: {
                    fontSize,
                },
            }),
        [isDarkMode, fontSize]
    );

    const value = useMemo(
        () => ({
            fontSize,
            setFontSize: (value: number) => {
                setFontSize(value);
            },
            isDarkMode,
            setIsDarkMode: (v: boolean) => {
                setIsDarkMode(v);
            },
            isDebug,
            setIsDebug: (v: boolean) => {
                setIsDebug(v);
            },
            licenseNumber,
            setLicenseNumber: (value: string) => {
                set('licenseNumber', value).then(r => setLicenseNumber(value));
            },
            countOfUnreadMessages,
            setCountOfUnreadMessages: (value: number) => {
                setCountOfUnreadMessages(value);
            },
        }),
        [isDarkMode, fontSize, isDebug, licenseNumber, countOfUnreadMessages]
    );

    useEffect(() => {
        (async () => {
            const l = await get<string>('licenseNumber');
            setLicenseNumber(l || '');
        })();
    }, []);

    useEffect(() => {
        if (user && user.hasRole && user?.hasRole(Role.ADMIN)) {
            setIsDebug(true);
        }
    }, [user]);

    return (
        <SettingsContext.Provider value={value}>
            <ThemeProvider theme={theme}>
                <GlobalStyles styles={{ '[data-shrink="true"]': { backgroundColor: theme.palette.background.default } }} />
                {children}
            </ThemeProvider>
        </SettingsContext.Provider>
    );
};
