import { AuthPayload } from '../@types/auth';

const decodePayload = (token: string): AuthPayload | undefined => {
    if (!token || !token.includes('.')) return;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
};

export { decodePayload };
